body{
    background-color: #f5f8fa;
}


.filter-icon{
    height: 16px;
    width: 28;
}

.nav-searchicon{
height: 18px;
width: 18px;
}

.nav-searchbtn{
    border: none;
    background-color: transparent;
    position: absolute;
    left: 303px;
    top: 4px;
}

.nav-searchbtn-white{
    border: none;
    background-color: white;
    position: absolute;
    left: 303px;
    top: 4px;
}

.searchbar-custom{
    width: 315px;
    border: none;
    background-color: #f5f8fb;
    padding-left: 28px;
}

.bggreen{
    background-color: #789C71;
    border-radius: 7px;
    height: 224px;
}

.whitecolor{
    background-color: white;
}

.bgorrange{
    background-color: #FF9548;
    border-radius: 7px;
    height: 224px;
}

.bgyellow{
    background-color: #FFB000;
    border-radius: 7px;
    height: 224px;
}

/* Main Container */

/* .dashboard-container {
    width: 88vw;
    position: absolute;
    right: 0;
} */

@media screen and (min-width:991px){
    .dashboard-container {
        width: 87vw;
    position: absolute;
    right: 0;
    }}

    @media screen and (min-width:375px) and (max-width: 934px){
        .bgorrange {
            height: 216px;
        }}

        @media screen and (min-width:375px) and (max-width: 934px){
            .bggreen {
                height: 216px;
            }}

            @media screen and (min-width: 870px){
                .bggreen{
                    width: 395px;
                }}

                @media screen and (min-width: 870px){
                    .bgorrange{
                        width: 395px;
                    }}

                    @media screen and (min-width: 870px){
                        .bgyellow{
                            width: 395px;
                        }}

h1{
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 21px;
}

.scrollme th{
    padding: 20px;
    text-align: center;
    color: rgb(14, 14, 14);
    font-weight: bolder;    
}




.scrollme tr{
    border-bottom: 1px rgb(193, 193, 193) solid;
}

.scrollme td{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    text-align: center;
    border-bottom-width: 0px;
}



.progress-bar{
    width: 32px;
    height: 7px;
    border-radius: 2px;
    background-color: #44bb80;
}

.progress-bar-0{
    background-color: #eef3f7;
    width: 32px;
    height: 7px;
    border-radius: 2px;
}

.Progress-bar{
    width: 72px;
    height: 4px;
    border-radius: 2px;
}

.backarrow:hover{
    cursor: pointer;
}


.Progress-bar-batch-details{
    width: 128px;
    height: 4px;
    border-radius: 2px;
    --bs-progress-bar-bg: #ffb000;
}

.card-batch-list{
    background-color: #a6b4fb;
    width: 100px;
}

.bgwhite-batchdetails{
    background-color: white;
    width: 278px;
    border-radius: 3px;
    padding-top: 16px;
    padding-bottom: 18px;
}

.assign-prog-border{
    background-color: #f5f8fa;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 4px;
    width: 165px;
}

.assign-prog-border:hover{
    background-color: #FF9548;
    color: white;
    cursor: pointer;
}


.batch-details:hover{
    cursor: pointer;
    color: blue;
}

.add-prog-pop{
    background-color: #eeeeee;
    width: 334px;
    display: flex;
    justify-content: center;
    position: relative;
}

.cancelimg{
    position: absolute;
    right: 10px;
    top: 25%;
}

.cancelimg:hover{
    cursor: pointer;
}

.atb-btn{
    width: 90%;
    height: 47px;
    border-radius: 5px;
    background-color: #f5f8fa;
    color: black;
    border: solid 1px rgb(237, 237, 237);
}

.atb-btn:hover{
    color: white;
    background-color: #ff9548;
    border: none;
}

.m-left-auto{
    margin-left: auto;
}

.scrollme{
    overflow-x: auto;
}

.programdetails-accordian{
    border: none;
}

.assignment-details-width{
    max-width: 298px;
}

.borderoutline{
    border: 1px solid rgb(127, 127, 127);
    border-radius: 5px;
    height: fit-content;
    width: 181px;
}

.remove-btn:hover{
cursor: pointer;
}

.more:hover{
cursor: pointer;
}

.more-assignment-hover:hover{
    cursor: pointer;
}

.cancelimg2{
    width: 8%;
    right: 14px;
    top: 19px;
}

.cancelimg2:hover{
    cursor: pointer;
}

.P-Update-Success-4{

    background-color: white;
    height: 234px;
      width: 334px;
      z-index: 20;

}


.score-card{
    background-color: white;
    height: 452px;
    width: 334px;
    z-index: 20;
    align-items: center
}

.dropdown-more{
    background-color: white;
    border: none;
}

.dropdown-more:hover{
    background-color: white;
    border: none;
}

.dropdown-toggle::after {
    display: none !important; 
  }

  .dropdown-more:focus{
    background-color: white;
    box-shadow: none;
}

.show>.btn-success.dropdown-toggle {
    background-color: white;
    border: none;
}

.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-success:active:focus {
    background-color: white;
    border: none;
}

.status-active {
    background-color: #edf9f3;
    font-weight: 400;
    width: 101px;
    border-radius: 30px;
    height: 30px;
    padding-top: 5px;
    font-size: 14px;
    margin-bottom: 0 !important;
}

.status-inactive {
    background-color: #fddada;
    font-weight: 400;
    width: 102px;
    border-radius: 30px;
    height: 30px;
    padding-top: 5px;
    font-size: 14px;
}

.create-User-Form h5{
    color: red;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-width: 1px;
}

.table>thead {
    vertical-align: middle;
}

thead{
    color: rgb(128, 128, 128);
}

.bggreen h6{
    margin-bottom: 0px;
    font-size: 14px;
}

.bggreen h3{
    margin-bottom: 0px;
    font-size: 20px;
}

.bgorrange h6{
    margin-bottom: 0px;
    font-size: 14px;
}

.bgorrange h3{
    margin-bottom: 0px;
    font-size: 20px;
}

.bgyellow h6{
    margin-bottom: 0px;
    font-size: 14px;
}

.bgyellow h5{
    margin-bottom: 0px;
    font-size: 24px;
    color: white;
}

.bgyellow h4{
    margin-bottom: 0px;
    font-size: 28px;
    color: white;
}

.batch-dates h5{
    font-size: 16px;
}

.batch-dates h6{
    font-size: 14px;
    color: rgb(59, 59, 59);
}

.bgwhite-batchdetails h6{
    margin-bottom: 0rem;
}

.bgwhite-batchdetails h5{
    margin-bottom: 0rem;
}

@media screen and (min-width:375px) and (max-width: 768px){
    .List-user-btn {
        margin-top: 10px;
        margin-bottom: 10px;
    }}

    @media screen and (min-width:375px) and (max-width: 768px){
        .profile-buttons {
            width: 500px;
        }}


        .atb-btn-grey{
            width: 90%;
    height: 47px;
    border-radius: 5px;
    background-color: #f5f8fa;
    color: black;
    border: solid 1px rgb(237, 237, 237);
        }

        .atb-btn-orange{
            background-color: #FF9548;
            width: 90%;
    height: 47px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    border: solid 1px rgb(237, 237, 237);
        }


        @media screen and (min-width:375px) and (max-width: 1250px){
            .assign-prog-border {
                width: 262px;
            }}

            @media screen and (min-width:604px) and (max-width: 977px){
                .assign-prog-border {
                    width: 262px;
                }}

.wrapperOffline {
    height: 100vh;
}

.msgBoxOffline{
    background-color: #789C71;
    color: white;
    border-radius: 8px;
    border-bottom: 8px solid #FFB000;
}

.msgBoxOffline h3{
    margin-bottom: 0px;
    margin-top: 12px;
}

.offline-icon{
    height: 52px;
    width: 52px;
}

.moduleCount{
    border: 1.2px solid black;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.submoduleCount{
    border: 1.2px solid black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

/* Pagination */

.pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
  }
  
  .pagination a {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #ffdfc8;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
  }

  .height15{
    height: 15px;
  }

  .height20{
    height: 20px;
  }

  @media screen and (min-height:600px) and (max-height: 655px){
    .dash1 {
        padding-top: 10px !important;
    }}

    @media screen and (min-height:600px) and (max-height: 655px){
        .dash6 {
            padding-top: 28px !important;
        }}

        @media screen and (min-height:525px) and (max-height: 600px){
            .dash1 {
                padding-top: 10px !important;
            }}

            @media screen and (min-height:525px) and (max-height: 600px){
                .dash2 {
                    padding-top: 8px !important;
                }}

                @media screen and (min-height:525px) and (max-height: 600px){
                    .dash3 {
                        padding-top: 8px !important;
                    }}

                    @media screen and (min-height:525px) and (max-height: 600px){
                        .dash4 {
                            padding-top: 8px !important;
                        }}

                        @media screen and (min-height:525px) and (max-height: 600px){
                            .dash5 {
                                padding-top: 8px !important;
                            }}

        @media screen and (min-height:525px) and (max-height: 600px){
            .dash6 {
                padding-top: 28px !important;
            }}