@font-face {
  font-family: "ProximaNova-Regular.otf";
  src: local("ProximaNova-Regular.otf"),
   url("../fonts/Proximanova/ProximaNova-Regular.otf") format("truetype");
  font-weight: bold;
  }

.multiselectdropdown{
  width: 100%;
}

* {
  font-family: "ProximaNova-Regular.otf";
    padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  }
  
  body{
  background-color: #ffffff;
  }
  
  p {
    margin: 0px;
  }
  .required:after { content:" *"; color: red; }
  /* colors */
  .red {
    color: red !important;
  }
  .green {
    color: green !important;
  }
  .blue{
    color: blue;
  }
  .black {
    color: black;
  }
  .grey2{
    color: rgb(184, 184, 184)
  }
  .black60{
    color: rgba(0, 0, 0, .6);
  }
  .black80{
    color: rgba(0, 0, 0, .8);
  }
  /* fontsizes */
  .font12{
    font-size: 12px;
  }

  .font13{
    font-size: 13px;
  }

  .font14{
    font-size: 14px;
  }
  .font16{
    font-size: 16px;
  }
  .font18{
    font-size: 18px;
  }
  .font20{
    font-size: 20px;
  }
  .font22{
    font-size: 22px;
  }
  .font24{
    font-size: 24px;
  }
  .font26{
    font-size: 26px;
  }
  .pointer{
    cursor: pointer;
  }
  /* border */
  .borderred{
    border: 1px solid red;
  }
  .bordergray{
    border: 1px solid gray;
  }
  /* fonts */
  .bold{
    font-weight: bold;
  }
  /* extra styles */
  .underline{
    text-decoration: underline;
  }

  /* height */
  .vh-100{
    height: 100vh;
  }
/* 
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); ;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) ;
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  } */

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 5px !important;
}
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .3);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.errorimgsize{
  height: 225px;
  width: 225px;
}


.ranger {
  margin: auto;
}

.ranger:hover{
  cursor: pointer;
}

.ranger:focus::-webkit-slider-runnable-track {
  background: #ff9548;
}

.btn-primary:disabled {
    background-color: #eaeaea;
    color: #181818;
    border-color: #eaeaea;
}

.btn-primary {
  color: #fff;
  background-color: #6C63FF;
  border-color: #6C63FF;
}



/* fontsizes */
.font12{
  font-size: 12px;
}
.font10{
  font-size: 10px;
}
.font14{
  font-size: 14px;
}
.font16{
  font-size: 16px;
}
.font18{
  font-size: 18px;
}
.font20{
  font-size: 20px;
}
.font24{
  font-size: 24px;
}
.font28{
  font-size: 28px;
}
.font30{
  font-size: 30px;
}
.font32{
  font-size: 32px;
}
.font36{
  font-size: 36px;
}
/* colors */
.blue{
  color: blue !important;
}
.black{
  color: black;
}
.black60{
  color: rgba(0, 0, 0, 0.6);
}
.orangeCol{
  color: #FF9548;
}
.white {
  color: white;
}
.signincolor {
  background-color: #F2F7FB;
}
/* background colors */
.background-black {
  background-color: black;
}
.background-black60{
  background-color: rgba(0, 0, 0, 0.6);
}
.background-black40{
  background-color: rgba(0, 0, 0, 0.4);
}
.background-black20{
  background-color: rgba(0, 0, 0, 0.2);
}
.background-gray {
  background-color: gainsboro;
}
.background-aliceblue{
  background-color: #f5f8fa;
}
.background-accordion {
  background-color: #f5f8fa;
}
.background-none {
  background: none !important;
}
.background-organe{
  background-color: #FF9548;
}
/* borders */
.border-black60{
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.border-orange{
  border: 1px solid #FF9548;
}
/* border radius */
.radius5{
  border-radius: 5px;
}
.radius10{
  border-radius: 10px;
}
.radius15 {
  border-radius: 15px;
}
.radius20 {
  border-radius: 20px;
}
.radiushalf{
  border-radius: 50%;
}

/* others */
.pointer {
  cursor: pointer;
}



.bold {
  font-weight: bold;
}
.selectedoption{
  background-color: #efe2d9;
}
.multiselectdropdown{
  width: 100%;
}
.fixed-bottom{
  position: absolute;
  bottom: 0;
}
.modal-content{
  height: 100%
}
/* accordion styles */
.accordion-button{
  padding: 5px;
  background-color: #f5f8fa;
}
.accordion-button:not(.collapsed) {
  background: none !important;
  box-shadow: none !important;
  /* color: black !important; */
}
.accordion-body{
  padding: .5rem 1.5rem;
}
.accordion-button:focus{
  border: none !important;
  box-shadow: none !important;
}
/* video styles */
.videotag{
  width: 100%;
}

/* input styles */
.outlinenone:focus{
  outline: none !important;
}
.placeholder-font14::placeholder {
  opacity: 0.19;
  color: #111;
  font-size: 14px;
  }
  
  .form-select-2{

    height: 43px;
    text-align: start;
    width: 100%;
    border: solid 1px rgb(195, 195, 195);
    color:  rgb(94, 94, 94);

}

/* spinner */
.spinner{
  width: 1.75rem;
  height: 1.75rem;
}

.modal-header {
  border-bottom: none;
  background-color: rgb(237, 237, 237);
}


.scoreBorder{
  width: 60px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #6C63FF;
  box-shadow: 1px 6px 10px -9px rgba(77, 77, 77, 0.75);
}


input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
display: none;
}



.heightDate{
  height: 43px;
  border: 1px solid rgb(199, 199, 199);
  padding-left: 12px;
  color: rgb(119, 119, 119);
}

.heightDate:focus{
  border: 1px solid rgb(199, 199, 199);
  border-color: #FF0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}




/* Left to Right Modal */


/* .modal-left {
  position: absolute;
  right: 0;
  left: 34%;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  width: 70%;
}

.modal-content{
  width: 755px;
} */

.modal{
  overflow-y: hidden !important;
}

.modal-left {
  position: absolute;
  right: 0;
  left: auto;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  width: 46% !important;
  height: 100% !important;
}


.modal-left-small {
  position: absolute;
  right: 0;
  left: auto;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
  width: 35% !important;
  height: 100% !important;
}

.modal-dialog {
  max-width: none; 
}

.modal-100 .modal-content{
  height: 100%;
}



/* .modal-content{
  width: min-content;
  margin-left: -265px;
} */

@media screen and (min-width:925px){
  .modal-left {
    width: 100%;
  }}

.fade-left {
  animation: fadeInLeft 0.5s ease-in-out;
}

@keyframes fadeInLeft {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

.fade-right {
  animation: fadeInRight 0.5s ease-in-out;
}

@keyframes fadeInRight {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(100%);
  }
}

.dateselect{
  background-color: transparent;
    position: absolute;
    right: 9%;
    top: 25%;
}

.cancelUser{
  height: 43px;
    border-radius: 5px;
    border: none;
    background-color: #6C63FF;
  color: white;
}


@media screen and (min-width: 375px) and (max-width: 991px){
  .cancelUser{
      width: inherit;
  }
  .mt160{
    margin-top: 100px !important;
  }
}

  @media screen and (min-width: 991px){
      .cancelUser{
          width: 345px;
      }}

      /* .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid \9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    } */

    .batchbtn{
      height: 41px;
      border-radius: 4px;
      background-color: #6C63FF;
      color: #F2F7FB;
      border: none;
      margin-top: 26px;
      width: fit-content;
    }


.pagination li{
  padding: 0 5px;
}

.updown{
  height: 14px;
  width: 8px;
}

.LogoUpdated{
  height: 40px;
}

.UpdatedMainColor{
  color: #6C63FF;
}

.UpdatedMainBGColor{
  background-color: #6C63FF;
}
.updateMainBorder{
  border: 1px solid #6C63FF;
}
.downloadProgrmMouseover:hover{
  background-color: #6C63FF;
  color: white;
}
.mb0{
  margin-bottom: 0px;
}

.mt106{
  margin-top: 106px;
}

.circleIcon{
  border-radius: 50%;
  padding: 8px;
  background-color: #F2F1FF;
}

.width160{
  width: 160px;
}

.mt160{
  margin-top: 130px;
}


.batchCards{
  /* border: solid 2px rgb(177, 177, 177); */
  background-color: white;
  border: 0;
  border-radius: 5px;
}

.bottomborder{
  /* border-top: 1px solid rgb(63, 63, 63); */
  border-bottom: 1px solid rgb(63, 63, 63);
}

.borderUpated{
  border: solid 2px rgb(177, 177, 177);
  color: rgb(155, 155, 155) ;
}

.tabDesign{
  border: 1px solid rgb(100, 100, 100);
    padding-top: 8px;
    border-radius: 5px;
}

/* .shadowLMS{
  box-shadow: 1px 6px 18px -10px rgba(62, 62, 62, 0.58);
} */


.userCards{
  color: black;
  background-color: white;
  border-radius: 5px;
}

.d-content{
  display: contents;
}

.width10{
  width: 10%
}


@media screen and (min-width: 1084px) and (max-width: 1289px){
  .width10{
      width: 12%;
  }
}

@media screen and (min-width: 990px) and (max-width: 1083px){
  .width10{
      width: 13%;
  }
}






.w-40{
  width: 40%;
}

/* .sidebtn:hover{
  background-color: #6C63FF;
} */

.focus {
  background: green;
  color: #000;
  border: 1px solid red;
  }

.scrollForm{
  overflow-x: hidden;
  overflow-y: auto;
  height: 70%;
}

/* .height-100{
  height: 100vh;
} */

.modal-backdrop{
  height: 100%;
}

.mt120{
  margin-top: 120px;
}

.change-pass{
  color: #6C63FF;
}

.change-pass:hover{
cursor: pointer;
}


.hoverDboard :hover{
  cursor: pointer;
  border: 1px solid #6C63FF;
}

.borderBottom{
  border-bottom: 1px solid rgb(182, 182, 182);
}

.width200{
  width: 200px;
}

.dashclick:hover{
  border: 1px solid #6C63FF;
}

/* 
input::file-selector-button {
  height: 41px;
      border-radius: 4px;
      background-color: #6C63FF;
      color: #F2F7FB;
      border: none;
      margin-top: 26px;
      width: fit-content;
} */

.updatebtn{
  height: 41px;
      border-radius: 4px;
      background-color: #6C63FF;
      color: #F2F7FB;
      border: none;
      width: fit-content;
}


.updatebtndisable{
  height: 41px;
      border-radius: 4px;
      background-color: #b4b4b4;
      color: #141414;
      border: none;
      width: fit-content;
}

.updatedesign{
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  width: 100%;
}