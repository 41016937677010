.profile-buttons{
    height: 28px;
    border-radius: 5px;
    font-size: 20px;
    background-color: #f5f8fa;
    color: black;
    border: none;
    margin: 0px 30px;
    padding-top: 2px;
}

.nav1{
    display: block;
}

.nav2{
    display: none;
}

.profile-buttons:hover{
    color: #6C63FF;
    border: none;
    padding-top: 2px;
    font-size: 20px;
    border-bottom: 3px solid #6C63FF;
    border-radius: 2px;
    padding-bottom: 42px;
}

.active-button {
    color: #6C63FF;
    border: none;
    margin: 0px 30px;
    font-size: 20px;
    padding-top: 2px;
    border-radius: 2px;
    border-bottom: 3px solid #6C63FF;
    padding-bottom: 42px;
}

/* .profile-buttons:active{
    background-color: black;
} */

.admin-pro-pic{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    /* background-image: url("../images/testimonialImg.jpg"); */
    background-size: cover;
    background-position: center center;
}

.admin-profile-update-fields{
    height: 43px;
}

.form-container h6{
font-weight: 400;
}

.form-select{

    /* height: 43px; */
    text-align: center

}

@media screen and (min-width: 375px) and (max-width: 991px){
    .profile-buttons-update{
        width: inherit;
    }}

    @media screen and (min-width: 991px){
        .profile-buttons-update{
            width: 345px;
        }}

        @media screen and (max-width: 992px) {
            .nav1{
                display: none;
            }
            .nav2{
                display: block;
            }
        }

.profile-buttons-update{
    height: 43px;
    border-radius: 5px;
    color: white;
    border: none;

}

.grey{
background-color: #e3e3e3;
color: rgb(29, 29, 29);
}

.orange{
    background-color: #FF9548;
}

.bgwhite{
    background-color: white;
}

.program-cards
{
    width: 31%;
    max-width: 31%;
    border-radius: 5px;
}
.programdetailcards{
    width: 25%;
    border-radius: 5px;
}
.graybackgroundbtn{
    width: 32px;
    height: 32px
}
.graybackgroundbtn:hover{
    background-color: gainsboro;
}
@media screen and (min-width:375px) and (max-width: 950px){
    .program-cards {
        width: 100%;
        max-width: 100%;
    }
    .programdetailcards{
        width: 45%;
        border-radius: 5px;
    }
}


/* 
.program-cards:hover{
    background-color: rgb(236, 236, 236);
    color: black;
    cursor: pointer;
} */

.border-file-upload{
    border: 3px rgb(220, 220, 220) dashed;
    border-radius: 8px; 
    padding-left: 100px;
    padding: 50px;
    width: 100%;
}

.edit-dropdown{
    bottom: 1%;
    right: 1%;

}

.update-picture{
    border: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f5f8fa;
}

.update-picture:hover{
    border: none;
    background-color: #FF9548;
    color: white;
}

.dropdown-more {
    background-color: white;
    border: none;
    border-radius: 50%;
}


.scroll-section{
    overflow-y: auto;
}

.scroll-container {
    height: 87%;
}

.right-container {
    height: 100vh;
    overflow: hidden;
}

.fit-content-h {
    height: fit-content;
}

@media screen and (max-width:991px) {
    .right-container {
        height: fit-content;
    }

    

    .scroll-container {
        height: 100%;
    }
}