.forgetpass-btn {
  height: 41px;
  /* width: 283px; */
  width: 100%;
  border-radius: 8px;
  background-color: #6C63FF;
  color: #F2F7FB;
  border: none;
  margin-top: 101px;
  /* margin-bottom: 111px; */
}
.forgetpass-spinner {
  margin-top: 101px !important;
}
.user{
height: 41px;
/* width: 283px; */
width: 100%;
border-radius: 8px;
background-color: #F2F7FB;
border: none;
margin-top: 10px;
margin-bottom: 18px;
padding-left: 20px;
}

.user::placeholder {
opacity: 0.19;
color: #111;
font-size: 14px;
line-height: 16px;
}

.flex-container {
height: 100vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.forgetpass-container{
height: 700px;
width: 1024px;
background-color: #ffffff;
display: flex;
justify-content: center;
box-shadow: 0px 12px 25px rgba(1,1,1,0.2);
justify-content: space-between;
}

.forgetpass-image{
height: 700px;
width: 571px;
background-image: url("https://www.mefa.org/uploads/news/161918_thinkstockphotos-647280728.jpg");
background-repeat: no-repeat;
background-size: cover;
  background-position-x: 60%;
  position: relative;

}

.color-fill {
background-color: rgba(1,225,147,0.35);
opacity: 0.9;
height: 700px;
width: 571px;
position: absolute;
top: 0;
left: 0;
}

.forgetpass-heading{
height: 35px;
width: 283px;
/* margin-left: 7px; */
color: #111;
font-size: 30px;
line-height: 35px;
margin-bottom: 0px;
}

.forgetpass-content{
display: flex;
flex-direction: column;
width: 453px;
height: 100%;
align-items: center;
}

.e-heading{
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
margin: 0px;
}


.p-heading{
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
margin: 0px;
}

.logo{
margin-bottom: 18px;
border-radius: 50px;
}

.logo img {
margin: 21px 21px 21px 21px;
}

.btn2{
color: #6C63FF;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
text-decoration: none;
background:none;
border:none;
margin:0;
padding:0;
cursor: pointer;
}

.mtop14{
margin-top: 14px;
}

.P-Update-Success-1{
height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  top: 0;
  left: 0;
  z-index: 10;
}

.P-Update-Success-2{
background-color: white;
height: 314px;
  width: 334px;
  z-index: 20;
}

.P-Update-Success-3 {
position: absolute;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: 1;
}

.w213{
width: 213px;
}
@media screen and (max-width:800px) {
  .forgetpass-btn {
    margin-top: 70px;
    margin-bottom: 0px;
  }
  .forgetpass-spinner {
    margin-top: 70px;
  }
}