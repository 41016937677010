  .verify-box{
    height: 40px;
    width: 36px;
    border-radius: 6px;
    border: 2px solid rgb(207, 207, 207);
    text-align: center;
}

.verify-otp-btn{
    height: 41px;
    /* width: 283px; */
    width: 100%;
    border-radius: 8px;
    background-color: #6C63FF;
    color: #F2F7FB;
    border: none;
    /* margin-bottom: 116px; */
    margin-top: 90px;
}

.update-pass-btn{
    height: 41px;
    /* width: 283px; */
    width: 100%;
    border-radius: 8px;
    background-color: #6C63FF;
    color: #F2F7FB;
    border: none;
    /* margin-bottom: 86px; */
    margin-top: 28px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}
.testInput > input[type="tel"]{
    margin-right: 10px !important;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    width: 35px !important;
    height: 35px !important;
}